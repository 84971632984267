<div class="jobDetails-section pt-23 pt-sm-27 pt-lg-27 pb-xl-10 pb-12">
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-xl-12 col-lg-12 mb-8 px-xxl-15 px-xl-0">
        <div class="bg-white rounded-4 border border-mercury shadow-9" style="overflow: hidden">

          <div class="p-3 bg-purple border-bottom border-width-1 border-default-color light-mode-texts">
            <div class="col-sm-12">
              <span class="text-white font-weight-bold monospace">
                自動入力設定 <span class="font-size-2 font-weight-light">※ 以下内容を設定すると、勤務実績入力する際に自動的に入力することができます。</span>
              </span>
            </div>
          </div>

          <div class="p-8">
            <form [formGroup]="autoInputForm" (ngSubmit)="submitAutoInputForm(autoInputForm.value)" novalidate>
              <div class="row">
                <div class="col-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">出勤時間</span>
                    <input type="text" formControlName="workStart"
                           [ngClass]="{'ng-dirty ng-invalid': submitted && autoInputForm.controls.workStart.errors}"
                           pInputText placeholder="HH:mm">
                  </div>
                </div>
                <div class="col-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">休始時間</span>
                    <input type="text" formControlName="resStart"
                           [ngClass]="{'ng-dirty ng-invalid': submitted && autoInputForm.controls.resStart.errors}"
                           pInputText placeholder="HH:mm">
                  </div>
                </div>
                <div class="col-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">休終時間</span>
                    <input type="text" formControlName="resEnd"
                           [ngClass]="{'ng-dirty ng-invalid': submitted && autoInputForm.controls.resEnd.errors}"
                           pInputText placeholder="HH:mm">
                  </div>
                </div>
                <div class="col-3">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">退勤時間</span>
                    <input type="text" formControlName="workEnd"
                           [ngClass]="{'ng-dirty ng-invalid': submitted && autoInputForm.controls.workEnd.errors}"
                           pInputText placeholder="HH:mm">
                  </div>
                </div>
                <div class="col-12 mt-7">
                  <div class="p-inputgroup">
                    <span class="p-inputgroup-addon">備考</span>
                    <textarea class="default-height" formControlName="employeeRemarks" [autoResize]="true"
                              placeholder="備考"
                              [ngClass]="{'ng-dirty ng-invalid': submitted && autoInputForm.controls.employeeRemarks.errors}"
                              pInputTextarea></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-7 d-flex justify-content-end">
                  <button type="submit" class="btn btn-danger text-uppercase font-weight-light font-size-3 mr-3" (click)="clearAll()">
                    クリア
                  </button>
                  <button type="submit" class="btn btn-info text-uppercase font-weight-light font-size-3">
                    {{ isCreate ? '登録' : '更新' }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
