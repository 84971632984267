import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-auto-input',
  templateUrl: './auto-input.component.html',
  styleUrls: ['./auto-input.component.css']
})
export class AutoInputComponent implements OnInit {

  public isCreate = true;
  public submitted = false;
  public autoInputForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.autoInputForm = this.formBuilder.group({
      workStart: new FormControl('', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      resStart: new FormControl('', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      resEnd: new FormControl('', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      workEnd: new FormControl('', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      employeeRemarks: new FormControl('', Validators.maxLength(1000)),
    });

    // 查看用户本地是否存在【用户设定的自动输入内容】
    Object.keys(this.autoInputForm.controls).forEach(key => {
      let autoInputLocalData = localStorage.getItem(key);
      if (autoInputLocalData) {
        // 初期值设定
        this.autoInputForm.controls[key].setValue(autoInputLocalData);
        // 更新を表示
        this.isCreate = false;
      }
    })
  }

  submitAutoInputForm(form: any) {
    if (this.autoInputForm.invalid) {
      return;
    }

    // 本地存储用户设定的自动输入的时间
    Object.keys(this.autoInputForm.controls).forEach(key => {
      localStorage.setItem(key, this.autoInputForm.controls[key].value);
    })
  }

  // 删除用户本地存储的[用户设定的自动输入的时间]
  clearAll() {
    Object.keys(this.autoInputForm.controls).forEach(key => {
      this.autoInputForm.controls[key].setValue('');
      localStorage.setItem(key, '');
    })
  }
}
