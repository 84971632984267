<header class="site-header site-header--menu-right bg-default py-7 py-lg-0 site-header--absolute site-header--sticky border-bottom">
  <div class="container">
    <nav class="navbar site-navbar offcanvas-active navbar-expand-lg px-0 py-0">
      <!-- logo -->
      <div class="brand-logo">
        <div class="row">
          <a routerLink="/">
            <h1 class="ml-5 mb-0 font-en">Timestamp</h1>
          </a>
          <h6 class="font-ch">AMS</h6>
        </div>
      </div>

      <!-- pc menu start -->
      <div class="collapse navbar-collapse" id="mobile-menu">
        <div class="navbar-nav-wrapper">
          <ul class="navbar-nav main-menu">
            <li class="nav-item">
              <a class="nav-link" routerLink="{{ROUTE_PATH.TIMESTAMP_ATTENDANCE}}">
                勤怠入力
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="{{ROUTE_PATH.TIMESTAMP_ATTENDANCE_VERIFY}}">
                勤務表確認
              </a>
            </li>

            <li *ngIf="isLogin" class="nav-item dropdown header-btn-devider active">
              <a class="nav-link" id="navbarDropdown" href="#features" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{name}} <i class="fa fa-angle-down pl-3"></i>
              </a>
              <ul class="gr-menu-dropdown dropdown-menu" aria-labelledby="navbarDropdown">
                <li class="drop-menu-item">
                  <span class="font-size-2 font-weight-light">出勤先:{{customerName}}</span>
                </li>
                <li class="drop-menu-item">
                  <a class="hover-pointer">
                    締め申請履歴
                  </a>
                </li>
                <li class="drop-menu-item">
                  <a class="hover-pointer" routerLink="{{ROUTE_PATH.TIMESTAMP_SETTING_AUTO_INPUT}}">
                    自動入力設定
                  </a>
                </li>
                <li class="drop-menu-item">
                  <a class="hover-pointer" (click)="logout()">
                    ログアウト
                  </a>
                </li>
              </ul>
            </li>
          </ul>

          <!-- mobile close menu button start -->
          <button class="d-block d-lg-none offcanvas-btn-close focus-reset" type="button" data-toggle="collapse"
                  data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="true"
                  aria-label="Toggle navigation">
            <i class="gr-cross-icon"></i>
          </button>
          <!-- mobile close menu button end -->

        </div>
      </div>
      <!-- pc menu end -->

      <!-- login button & register button start -->
      <div *ngIf="!isLogin" class="ml-auto pr-2 ml-lg-6 d-none d-xs-flex">
        <a routerLink="{{ROUTE_PATH.EMPTY}}" class="btn btn-red-2 font-size-3 rounded-5 text-white">
          ログイン
        </a>
      </div>
      <!-- login button & register button end -->

      <!-- mobile start -->
      <button class="navbar-toggler btn-close-off-canvas  hamburger-icon border-0" type="button"
              data-toggle="collapse" data-target="#mobile-menu" aria-controls="mobile-menu" aria-expanded="false"
              aria-label="Toggle navigation">
        <span class="hamburger hamburger--squeeze js-hamburger">
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
            </span>
            </span>
      </button>
      <!-- mobile end -->

    </nav>
  </div>
</header>
