import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../../service/auth.service";
import {StatusCode} from "../../../common/util/status-code";
import {RoutePath} from "../../../common/route-path";

@Component({
  selector: 'app-mail-verify',
  templateUrl: './mail-verify.component.html',
  styleUrls: ['./mail-verify.component.css']
})
export class MailVerifyComponent implements OnInit {

  public verifyStatus = '';
  public ROUTE_PATH = RoutePath;

  constructor(
    public statusCode: StatusCode,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) {

    this.activatedRoute.queryParams.subscribe(val => {
      if (val?.token !== undefined) {
        setTimeout(() => {
          // メール認証
          authService.mailVerify(val?.token).subscribe(res => {
            if (res !== undefined) {
              // データない404
              if (res.statusCode === this.statusCode.DATA_NOT_FOUND) {
                this.verifyStatus = this.statusCode.DATA_NOT_FOUND;
              }
              // トークン有効期限が切れ
              if (res.statusCode === this.statusCode.TOKEN_EXPIRE) {
                this.verifyStatus = this.statusCode.TOKEN_EXPIRE;
              }
              // すでに認証済み
              if (res.statusCode === this.statusCode.ALREADY_CERTIFIED) {
                this.verifyStatus = this.statusCode.ALREADY_CERTIFIED;
              }
              // 处理 SUCCESS
              if (res.statusCode === this.statusCode.SUCCESS) {
                this.verifyStatus = this.statusCode.SUCCESS;
              }
            }
          })
        }, 3000);
      }
    })
  }

  ngOnInit(): void {
  }

}
