import {Component, OnInit} from '@angular/core';
import {RoutePath} from "../../../common/route-path";
import {AuthService} from "../../../service/auth.service";
import {Router} from "@angular/router";
import {CookieUtil} from "../../../common/util/cookie-util";
import {StatusCode} from "../../../common/util/status-code";
import {Constant} from "../../../common/util/constant";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public isLogin = false;

  public name = '';
  public companyName = '';
  public customerName = '';

  public ROUTE_PATH = RoutePath;

  constructor(
    private router: Router,
    public statusCode: StatusCode,
    private cookieUtil: CookieUtil,
    private authService: AuthService,
  ) {
    // ログイン状態イベントを受け取る
    authService.isLogin.subscribe(val => {this.isLogin = val;});
    authService.name.subscribe(val => {this.name = val;});
    authService.companyName.subscribe(val => {this.companyName = val;});
    authService.customerName.subscribe(val => {this.customerName = val;});
  }

  ngOnInit(): void {
    const token = this.cookieUtil.getCookie(Constant.COOKIE.TIMESTAMP_ONE_TIME_TOKEN_);
    if (token !== null && token !== '') {
      this.isLogin = true;
      this.name = localStorage.getItem('name') as string;
      this.companyName = localStorage.getItem('companyName') as string;
      this.customerName = localStorage.getItem('customerName') as string;
    }
  }

  // 退出登录
  logout(): void {
    this.authService.logout().subscribe(res => {
      if (res !== undefined) {
        // 处理 SUCCESS
        if (res.statusCode === this.statusCode.SUCCESS) {
          this.isLogin = false;
          localStorage.removeItem('name');
          localStorage.removeItem('companyName');
          localStorage.removeItem('customerName');
          this.router.navigate([RoutePath.EMPTY]).then();
        }
      }
    });
  }

}
