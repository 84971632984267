import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FontService extends BaseService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  // 获取二进制的字体
  getFont(): Observable<any> {
    return this.http.get('assets/fonts/pdf-font/font.json')
      .pipe(
        map((response: any) => {
          return response.font;
        })
      );
  }
}

export interface Front {
  font?: string;
}
