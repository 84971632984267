import {EventEmitter, Injectable, Output} from '@angular/core';
import {BaseService} from '../base/base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  // ログイン状態イベントを定義
  @Output() isLogin: EventEmitter<boolean> = new EventEmitter();
  @Output() name: EventEmitter<string> = new EventEmitter();
  @Output() companyName: EventEmitter<string> = new EventEmitter();
  @Output() customerName: EventEmitter<string> = new EventEmitter();

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  // 社員ログイン
  loginSubmit(form: any): Observable<any> {
    return this.http.post(this.API_PATH.API_TIMESTAMP_AUTH_LOGIN, form, this.httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // メール認証
  mailVerify(token: string): Observable<any> {
    return this.http.get(this.API_PATH.API_TIMESTAMP_AUTH_MAIL + '/' + token, this.httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  // 退出登录
  logout(): Observable<any> {
    return this.http.get(this.API_PATH.API_TIMESTAMP_AUTH_LOGOUT, this.httpOptions)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }
}
