<div class="pt-8 pb-12 pt-lg-30">
  <div class="container">
    <div class="row justify-content-center mt-32 mb-35">
      <div class="col-xxl-4">
        <div *ngIf="verifyStatus === ''" class="px-9 pt-9 pb-7">
          <div class="text-center">
            <div class="wrapper">
              <div class="typing-demo">
                メールアドレス確認中...
              </div>
            </div>
          </div>
          <div class="text-center">
            <img src="./assets/image/download.gif" width="190" alt="loader">
          </div>
        </div>
        <div *ngIf="verifyStatus === statusCode.DATA_NOT_FOUND" class="mt-25 mb-22">
          <div class="text-center">
            This request processing failed. code 404
          </div>
        </div>

        <div *ngIf="verifyStatus === statusCode.TOKEN_EXPIRE" class="mt-24 mb-20">
          <div class="text-center">
            トークンの有効期限が切れたため、<br>担当者までご連絡ください
          </div>
        </div>

        <div *ngIf="verifyStatus === statusCode.ALREADY_CERTIFIED" class="mt-25 mb-22">
          <div class="text-center">
            すでに認証済みです
          </div>
        </div>

        <div *ngIf="verifyStatus === statusCode.SUCCESS" class="px-9 pt-9 pb-7">
          <div class="page-wrapper">
            <div class="circle-wrapper">
              <div class="success circle"></div>
              <div class="icon">
                <i class="fa fa-check"></i>
              </div>
            </div>
          </div>
          <div class="text-center">
            メールアドレス確認できました
            <a routerLink="{{ROUTE_PATH.EMPTY}}" class="btn btn-purple font-size-3 rounded-5 text-white w-100 mt-5">
              ご利用開始
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
