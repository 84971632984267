import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../service/auth.service";
import {StatusCode} from "../../../common/util/status-code";
import {CookieUtil} from "../../../common/util/cookie-util";
import {Router} from "@angular/router";
import {RoutePath} from "../../../common/route-path";
import {Constant} from "../../../common/util/constant";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  // -------------------------
  public submitted = false;
  public showSubmitLoader = false;
  public errorMessage = '';
  public loginForm!: FormGroup;

  // -------------------------
  constructor(
    private router: Router,
    public statusCode: StatusCode,
    private cookieUtil: CookieUtil,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    // 如果Cookie存在 则返回DashBoard页面，防止用户登录的状态通过URL访问该页面
    const uid = this.cookieUtil.getCookie(Constant.COOKIE.TIMESTAMP_ONE_TIME_TOKEN_);
    if (uid !== null && uid !== '') { // 已登录
      this.router.navigate([RoutePath.TIMESTAMP_ATTENDANCE]).then();
    }

    this.loginForm = this.formBuilder.group({
      mail: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]),
      remember: new FormControl(''),
    });

    // 本地储存的账号, Logout时展示登录成功的账号
    this.loginForm.controls.mail.setValue(localStorage.getItem('mail'));
  }

  loginSubmit(form: any) {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.showSubmitLoader = true;

    // login form submit
    this.authService.loginSubmit(form).subscribe(res => {
      if (res !== undefined) {
        // 用户不存在 or 账号或密码错误
        if (res.statusCode === this.statusCode.INCORRECT_ACCOUNT_PASSWORD) {
          this.loginForm.controls.password.setErrors({password: true});
          this.errorMessage = 'ユーザー名かパスワードが正しくありません';
        }

        // 未认证用户
        if (res.statusCode === this.statusCode.ACCOUNT_UNAUTHENTICATED) {
          this.errorMessage = 'メールアドレス認証を行いください';
        }

        // 账号停止使用
        if (res.statusCode === this.statusCode.ACCOUNT_USE_STOP) {
          this.errorMessage = 'このアカウントは使用停止されています';
        }

        // 处理 SUCCESS
        if (res.statusCode === this.statusCode.SUCCESS) {

          // ログイン状態イベントを設定し、header component側で受け取る
          this.authService.isLogin.emit(true);
          this.authService.name.emit(res?.result?.name);
          this.authService.companyName.emit(res?.result?.companyName);
          this.authService.customerName.emit(res?.result?.customerName);

          // 本地保存用户账号(方便用户ログアウト后输入框默认展示上次登录的mail)
          localStorage.setItem('mail', form?.mail);

          // 本地保存ManageNo
          localStorage.setItem('manageNo', res?.result?.manageNo);
          // 本地保存ShowName
          localStorage.setItem('name', res?.result?.name);
          // 本地保存勤怠利用者の企业名
          localStorage.setItem('companyName', res?.result?.companyName);
          // 本地保存勤怠利用者の顧客(作業先)名
          localStorage.setItem('customerName', res?.result?.customerName);

          this.router.navigate([RoutePath.TIMESTAMP_ATTENDANCE]).then();
        }

        this.showSubmitLoader = false;
      }
    });
  }
}
