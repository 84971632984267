<div class="jobDetails-section pt-23 pt-sm-27 pt-lg-27 pb-xl-10 pb-12">
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
        <div class="bg-white rounded-4 border border-mercury shadow-9" style="overflow: hidden">

          <div class="p-3 bg-purple border-bottom border-width-1 border-default-color light-mode-texts">
            <div class="col-sm-12">
              <span class="text-white font-weight-bold monospace">
                勤怠入力
              </span>
            </div>
          </div>

          <p-progressBar [mode]=" isShowLoader ? 'indeterminate' : ''"  [styleClass]="'progress-bar'"></p-progressBar>

          <div class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
            <div class="row">
              <div class="col-md-12 pl-lg-0">
                <div class="text-center">
                  <h6 class="font-size-9 text-black-2 font-weight-bold monospace mb-0">{{nowTime}}</h6>
                  <span class="font-size-5 d-block mb-4 text-gray monospace">{{nowDate}}&nbsp;({{dayOfWeek}})</span>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="pt-5">
                <div class="row justify-content-center">
                  <div class="col-6 col-xl-3 col-lg-3" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                    <div class="pb-xl-0 text-center">
                      <p class="text-hit-gray font-size-3 monospace mb-0">
                        出勤:&nbsp;&nbsp;
                        <span *ngIf="!showTopInitLoader">
                          <span *ngIf="statusHintNum > 1" class="text-info font-size-3 font-weight-bold mb-0">{{statusHintStrList[0]}}</span>
                          <span *ngIf="statusHintNum <= 1" class="mb-0">--</span>
                        </span>
                        <span *ngIf="showTopInitLoader">
                          <span class="mb-0">・・・</span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="col-6 col-xl-3 col-lg-3" data-aos="fade-right" data-aos-duration="900" data-aos-once="true">
                    <div class="pb-xl-0 text-center">
                      <p class="text-hit-gray font-size-3 monospace mb-0">
                        休始:&nbsp;&nbsp;
                        <span *ngIf="!showTopInitLoader">
                          <span *ngIf="statusHintNum > 2" class="text-info font-size-3 font-weight-bold mb-0">{{statusHintStrList[1]}}</span>
                          <span *ngIf="statusHintNum <= 2" class="mb-0">--</span>
                        </span>
                        <span *ngIf="showTopInitLoader">
                          <span class="mb-0">・・・</span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-xl-3 col-lg-3" data-aos="fade-left" data-aos-duration="900" data-aos-once="true">
                    <div class="pb-xl-0 text-center">
                      <p class="text-hit-gray font-size-3 monospace mb-0">
                        休終:&nbsp;&nbsp;
                        <span *ngIf="!showTopInitLoader">
                          <span *ngIf="statusHintNum > 3" class="text-info font-size-3 font-weight-bold mb-0">{{statusHintStrList[2]}}</span>
                          <span *ngIf="statusHintNum <= 3" class="mb-0">--</span>
                        </span>
                        <span *ngIf="showTopInitLoader">
                          <span class="mb-0">・・・</span>
                        </span>
                      </p>
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-xl-3 col-lg-3" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                    <div class="pb-xl-0 text-center">
                      <p class="text-hit-gray font-size-3 monospace mb-0">
                        退勤:&nbsp;&nbsp;
                        <span *ngIf="!showTopInitLoader">
                          <span *ngIf="statusHintNum > 4" class="text-info font-size-3 font-weight-bold mb-0">{{statusHintStrList[3]}}</span>
                          <span *ngIf="statusHintNum <= 4" class="mb-0">--</span>
                        </span>
                        <span *ngIf="showTopInitLoader">
                          <span class="mb-0">・・・</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">

            <div class="mt-10 mb-10" style="text-align: -webkit-center">
              <div *ngIf="!showCenterInitLoader">
                <div *ngIf="statusNum <= 4" class="work-box hover-pointer" (click)="postWorkStartOrEndTime()">
                  <div *ngIf="statusNum == 1" class="work-wave bg-primary"></div>
                  <div *ngIf="statusNum == 2" class="work-wave bg-indigo"></div>
                  <div *ngIf="statusNum == 3" class="work-wave bg-poppy"></div>
                  <div *ngIf="statusNum == 4" class="work-wave bg-blue"></div>
                  <div class="work-title">
                    {{statusStr}}
                  </div>
                  <hr>
                </div>

                <div *ngIf="statusNum == 5" class="stop_box">
                  <div class="stop_wave">
                    <img src="./assets/image/man.svg" class="w-20 pt-15" alt="logo">
                    <img src="./assets/image/girl.svg" class="w-20 pt-15" alt="logo">
                  </div>
                  <hr>
                  <div class="stop_title">
                    {{statusStr}}
                  </div>
                </div>
              </div>

              <div *ngIf="showCenterInitLoader">
                <div class="work-box hover-pointer" (click)="postWorkStartOrEndTime()">
                  <div class="work-circle"></div>
                  <div class="work-circle-title">
                    Loader...
                  </div>
                  <hr>
                </div>
              </div>
            </div>

            <div class="col-sm-12 pl-lg-0">
              <div class="text-center">
                <h6 *ngIf="!showBottomInitLoader" class="font-size-6 text-black-2 font-weight-bold text-gray monospace mb-0">現在の状態:&nbsp;&nbsp;{{nowStatusStr}}</h6>
                <h6 *ngIf="showBottomInitLoader" class="font-size-6 text-black-2 font-weight-bold text-gray monospace mb-0">現在の状態:&nbsp;&nbsp;・・・</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
