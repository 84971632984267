import {FormControl} from '@angular/forms';

export class AroundFormControl extends FormControl {
  private _isCheckLocalExist: boolean;

  constructor(isCheckLocalExist, formState, validatorOrOpts?) {
    super(formState, validatorOrOpts);
    this._isCheckLocalExist = isCheckLocalExist;
  }

  get isCheckLocalExist(): boolean {
    return this._isCheckLocalExist;
  }

  set isCheckLocalExist(value: boolean) {
    this._isCheckLocalExist = value;
  }
}
