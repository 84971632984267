import {Injectable} from '@angular/core';
import {BaseService} from '../base/base-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ResponseStatusCheckService} from "../common/util/response-status-check.service";
import {QueryAttendanceRecordConditionForm} from "../form/query-condition-form/query-attendance-record-condition-form";

@Injectable({
  providedIn: 'root'
})
export class AttendanceRecordService extends BaseService {

  constructor(
    private http: HttpClient,
    private responseCheck: ResponseStatusCheckService
  ) {
    super();
  }

  // 初期化(当日の出勤状態取得)
  attendanceRecordInit(): Observable<any> {
    return this.http.get(this.API_PATH.API_TIMESTAMP_RECORD_INIT, this.httpOptions)
      .pipe(
        map((response: any) => {
          return this.responseCheck.statusCheck(response);
        })
      );
  }

  // 出勤、休始、休終、退勤(勤怠入力画面から)
  attendanceRecordCreate(): Observable<any> {
    return this.http.get(this.API_PATH.API_TIMESTAMP_RECORD_CREATE, this.httpOptions)
      .pipe(
        map((response: any) => {
          return this.responseCheck.statusCheck(response);
        })
      );
  }

  // 検索勤怠記録
  getAttendanceRecordByDate(conditionForm: QueryAttendanceRecordConditionForm): Observable<any> {
    return this.http.post(this.API_PATH.API_TIMESTAMP_RECORD_LIST, conditionForm, this.httpOptions)
      .pipe(
        map((response: any) => {
          return this.responseCheck.statusCheck(response);
        })
      );
  }

  // get holiday calender
  getJapanHoliday(date: string): Observable<any> {
    // date format is: yyyyMM
    // 该URL是日本政府提供的Webインターフェース
    // URL: https://national-holidays.jp/about.html
    return this.http.get('https://api.national-holidays.jp/' + date)
      .pipe(
        map((response: any) => {
          return response
        })
      );
  }

  // 出勤、休始、休終、退勤(勤怠表確認画面から)
  attendanceRecordUpdate(form: any): Observable<any> {
    return this.http.post(this.API_PATH.API_TIMESTAMP_RECORD_UPDATE, form, this.httpOptions)
      .pipe(
        map((response: any) => {
          return this.responseCheck.statusCheck(response);
        })
      );
  }

  // 勤怠記録締め申請
  attendanceRecordCloseRequestSubmit(form: any): Observable<any> {
    return this.http.post(this.API_PATH.API_TIMESTAMP_RECORD_CLOSE_REQUEST, form, this.httpOptions)
      .pipe(
        map((response: any) => {
          return this.responseCheck.statusCheck(response);
        })
      );
  }
}
