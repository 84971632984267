import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LoginComponent} from "./component/auth/login/login.component";
import {AttendanceVerifyComponent} from "./component/attendance-verify/attendance-verify.component";
import {AttendanceComponent} from "./component/attendance/attendance.component";
import {RoutePath} from "./common/route-path";
import {MailVerifyComponent} from "./component/auth/mail-verify/mail-verify.component";
import {AuthGuard} from "./service/auth-guard";
import {UnknownErrorComponent} from "./component/errors/unknown-error/unknown-error.component";
import {SystemErrorComponent} from "./component/errors/system-error/system-error.component";
import {AutoInputComponent} from "./component/setting/auto-input/auto-input.component";

@NgModule({
  imports: [RouterModule.forRoot([
    {path: RoutePath.EMPTY, component: LoginComponent},
    // メール認証
    {path: RoutePath.TIMESTAMP_AUTH_MAIL, component: MailVerifyComponent},
    // 出勤入力
    {path: RoutePath.TIMESTAMP_ATTENDANCE, component: AttendanceComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},
    // 勤務表確認
    {path: RoutePath.TIMESTAMP_ATTENDANCE_VERIFY, component: AttendanceVerifyComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},

    // 自動入力設定
    {path: RoutePath.TIMESTAMP_SETTING_AUTO_INPUT, component: AutoInputComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard]},

    // システムエラー
    {path: RoutePath.SYSTEM_ERROR, component: SystemErrorComponent},
    // 未知异常
    {path: RoutePath.UNKNOWN_ERROR, component: UnknownErrorComponent},
  ], {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
