import {environment} from '../../environments/environment';

export class ApiPath {
  API_PATH = {

    API_TIMESTAMP_AUTH_LOGIN: environment.API_BASE + '/api/timestamp/auth/login',
    API_TIMESTAMP_AUTH_LOGOUT: environment.API_BASE + '/api/timestamp/auth/logout',
    API_TIMESTAMP_AUTH_MAIL: environment.API_BASE + '/api/timestamp/auth/mail',

    API_TIMESTAMP_RECORD_INIT: environment.API_BASE + '/api/timestamp/record/init',
    API_TIMESTAMP_RECORD_CREATE: environment.API_BASE + '/api/timestamp/record/create',
    API_TIMESTAMP_RECORD_UPDATE: environment.API_BASE + '/api/timestamp/record/update',
    API_TIMESTAMP_RECORD_LIST: environment.API_BASE + '/api/timestamp/record/list',
    API_TIMESTAMP_RECORD_CLOSE_REQUEST: environment.API_BASE + '/api/timestamp/record/close/request',

  };
}
