<div class="jobDetails-section pt-23 pt-sm-27 pt-lg-27 pb-xl-10 pb-12">
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-xl-12 col-lg-12 mb-8 px-xxl-15 px-xl-0">
        <div class="bg-white rounded-4 border border-mercury shadow-9" style="overflow: hidden">

          <div class="p-3 bg-purple border-bottom border-width-1 border-default-color light-mode-texts">
            <div class="col-sm-12">
              <span class="text-white font-weight-bold monospace">
                勤務表確認
              </span>
            </div>
          </div>

          <p-progressBar [mode]=" isShowLoader ? 'indeterminate' : ''" [styleClass]="'progress-bar'"></p-progressBar>

          <div
            class="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-8 border-width-1 border-default-color light-mode-texts">
            <div class="row">
              <div class="col-md-12 pl-lg-0">
                <div class="text-center">
                  <h6 class="font-size-8 text-black-2 font-weight-bold monospace mb-0">
                    {{nowDate | date: 'YYYY年MM月度'}}
                  </h6>
                  <span class="font-size-5 d-block mb-4 text-gray monospace">出勤先: {{customerName}}</span>
                </div>
              </div>

            </div>

            <div class="container">
              <div class="pt-5">
                <div class="row justify-content-center" style="display: block; overflow: hidden">
                  <p-table #pTable [value]="attendanceRecordList" [(selection)]="selectedAttendanceRecord"
                           selectionMode="single" styleClass="p-datatable-gridlines" [scrollable]="true">
                    <ng-template pTemplate="header">
                      <tr>
                        <th colspan="9">
                          <div class="container">
                            <div class="row justify-content-around">
                              <div class="col-4 text-left pr-0 pl-0">
                                <p-calendar (onSelect)="getAttendanceRecord()"
                                            [(ngModel)]="nowDate" view="month" [styleClass]="'w-100'"
                                            [maxDate]="maxDate" [minDate]="minDate"
                                            dateFormat="yy年mm月" [readonlyInput]="true"
                                ></p-calendar>
                              </div>
                              <div class="col-8 text-right pr-0 pl-0">
                                <!--                                <button (click)="pTable.exportCSV()"-->
                                <!--                                        class="btn btn-outline-gray text-uppercase font-weight-light font-size-3 mr-3">-->
                                <!--                                  CSV出力-->
                                <!--                                </button>-->
                                <!--                                <button (click)="exportExcel()"-->
                                <!--                                        class="btn btn-outline-gray text-uppercase font-weight-light font-size-3 mr-3">-->
                                <!--                                  XLS出力-->
                                <!--                                </button>-->
                                <button [disabled]="isRecordExists" (click)="exportPDF()"
                                        [ngClass]="isRecordExists ? 'not-allowed' : ''"
                                        class="btn btn-outline-gray text-uppercase font-weight-light font-size-3 mr-3">
                                  PDF出力
                                </button>
                                <!--                                <button [disabled]="true" class="btn btn-info text-uppercase font-weight-light font-size-3 mr-3">-->
                                <!--                                  稼働日編集-->
                                <!--                                </button>-->
                                <button [disabled]="isClose || isNotRecord" (click)="showCloseRequestSubmitDialog()"
                                        [ngClass]="isClose || isNotRecord ? 'not-allowed' : ''"
                                        class="btn btn-danger text-uppercase font-weight-light font-size-3">
                                  {{isClose ? '締め申請済み' : '締め申請'}}
                                </button>
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th class="m-w-150">日付</th>
                        <th class="m-w-150">国民祝日</th>
                        <th class="m-w-100">出勤時間</th>
                        <th class="m-w-100">休始時間</th>
                        <th class="m-w-100">休終時間</th>
                        <th class="m-w-100">退勤時間</th>
                        <th class="m-w-100">勤務時間</th>
                        <th class="m-w-100">備考</th>
                        <th class="m-w-100">編集</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record>
                      <tr [ngClass]="record['isWeekOrHoliday'] ? 'light-grey' : ''">
                        <td class="m-w-150"
                            [ngClass]="record['isWeekOrHoliday'] ? 'text-danger' : ''">{{record['dateAndWeek']}}</td>
                        <td class="text-danger m-w-150">{{record['holiday']}}</td>
                        <td class="m-w-100">{{record['workStart']}}</td>
                        <td class="m-w-100">{{record['resStart']}}</td>
                        <td class="m-w-100">{{record['resEnd']}}</td>
                        <td class="m-w-100">{{record['workEnd']}}</td>
                        <td class="m-w-100">{{record['totalWorkTimeOfDay']}}</td>
                        <td class="m-w-100 text-ellipsis">
                          <span class="font-size-1 font-weight-light text-ellipsis"
                                [title]="record['employeeRemarks']">
                            {{record['employeeRemarks']}}
                          </span>
                        </td>
                        <td class="m-w-100">
                          <a *ngIf="!isApproved" (click)="showAttendanceRecordDetailDialog(record)"
                             class="font-size-3 text-secondary text-decoration hover-pointer">編&nbsp;集</a>
                          <span *ngIf="isApproved" class="font-size-3 text-indigo text-decoration not-allowed">編&nbsp;集</span>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                      <tr>
                        <td style="min-width: 1102.6px">
                          <div class="container">
                            <div class="row justify-content-around">
                              <div class="col-6 text-left pr-0 pl-0">
                                No.{{manageNo}}
                              </div>
                              <div class="col-6 text-right pr-0 pl-0">
                                今月稼働時間: {{totalWorkTimeOfMonth}}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- create or update -->
<form [formGroup]="attendanceForm" (ngSubmit)="submitAttendanceRecordConfirm(attendanceForm.value)" novalidate>
  <p-dialog [styleClass]="'dialog-width'" header="{{detailDialogTitle}}" [(visible)]="isShowDetailDialog"
            (onHide)="attDialogClose()">
    <div class="row" style="min-width: 960px">
      <div class="col-12 pb-0">
        <div class="text-center mb-5">
          <span class="font-size-3 text-danger">
            <i *ngIf="formValidationErrorMessage" class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            {{formValidationErrorMessage}}
          </span>
        </div>
      </div>
      <div class="col-3 pb-0">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">出勤時間</span>
          <input type="text" formControlName="workStart"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && attendanceForm.controls.workStart.errors}"
                 pInputText placeholder="HH:mm">
        </div>
      </div>
      <div class="col-3 pb-0">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">休始時間</span>
          <input type="text" formControlName="resStart"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && attendanceForm.controls.resStart.errors}"
                 pInputText placeholder="HH:mm">
        </div>
      </div>
      <div class="col-3 pb-0">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">休終時間</span>
          <input type="text" formControlName="resEnd"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && attendanceForm.controls.resEnd.errors}"
                 pInputText placeholder="HH:mm">
        </div>
      </div>
      <div class="col-3 pb-0">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">退勤時間</span>
          <input type="text" formControlName="workEnd"
                 [ngClass]="{'ng-dirty ng-invalid': submitted && attendanceForm.controls.workEnd.errors}"
                 pInputText placeholder="HH:mm">
        </div>
      </div>
      <div class="col-12 pb-0 mt-7">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">備考</span>
          <textarea class="default-height" formControlName="employeeRemarks" [autoResize]="true" placeholder="備考"
                    [ngClass]="{'ng-dirty ng-invalid': submitted && attendanceForm.controls.employeeRemarks.errors}"
                    pInputTextarea></textarea>
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button type="button" (click)="isShowDetailDialog = false" label="取消" icon="fa fa-times"
                styleClass="p-button-sm p-button-danger mr-6"></p-button>
      <p-button type="submit" [label]="isCreate ? '登録' : '更新'"
                [disabled]="isShowSubmitLoader"
                [icon]="isShowSubmitLoader ? 'fa fa-refresh fa-spin fa-2x fa-fw' : 'fa fa-check'"
                styleClass="p-button-sm p-button-info mr-0">
      </p-button>
    </ng-template>
  </p-dialog>
</form>

<!-- close submit -->
<form [formGroup]="closeSubmitForm" (ngSubmit)="submitCloseRequestConfirm(closeSubmitForm.value)" novalidate>
  <p-dialog [styleClass]="'dialog-width'" header="{{nowDate | date: 'YYYY年MM月度'}}締め申請" [(visible)]="isShowCloseSubmitDialog"
            (onHide)="attDialogClose()">
    <div class="row" style="min-width: 960px">
      <div class="col-12 pb-0">
        <div class="text-center mb-5">
          <span class="font-size-3 text-warning">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            担当者に承認されるまで、締め申請した月度の勤怠内容に対して再修正することもできます。
          </span>
        </div>
      </div>
      <div class="col-12 pb-0 mt-7">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">備考</span>
          <textarea class="default-height" formControlName="remarks"
                    [ngClass]="{'ng-dirty ng-invalid': submitted && closeSubmitForm.controls.remarks.errors}"
                    [autoResize]="true" pInputTextarea>
          </textarea>
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button type="button" (click)="isShowCloseSubmitDialog = false" label="取消" icon="fa fa-times"
                styleClass="p-button-sm p-button-danger mr-6"></p-button>
      <p-button type="submit" [label]="isCreate ? '登録' : '更新'"
                [disabled]="isShowSubmitLoader"
                [icon]="isShowSubmitLoader ? 'fa fa-refresh fa-spin fa-2x fa-fw' : 'fa fa-check'"
                styleClass="p-button-sm p-button-info mr-0">
      </p-button>
    </ng-template>
  </p-dialog>
</form>
