<footer class="footer dark-mode-texts">
  <div class="container">
    <div class="col-xxl-12 col-lg-12 col-md-12">
      <div class="text-center">
        <p class="widget-title font-size-3 text-gray mb-0">
          <a>Around Manage System (AMS)</a>
        </p>
        <p class="widget-title font-size-3 text-gray mb-5">
          Copyright ©2021 All rights reserved | Yamato, Corp.
        </p>
      </div>
    </div>
  </div>
</footer>
