import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './component/common/footer/footer.component';
import {HeaderComponent} from "./component/common/header/header.component";
import {LoginComponent} from "./component/auth/login/login.component";
import {AttendanceVerifyComponent} from './component/attendance-verify/attendance-verify.component';
import {NgxUsefulSwiperModule} from "ngx-useful-swiper";
import {AttendanceComponent} from './component/attendance/attendance.component';
import {DatePipe, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {MailVerifyComponent} from './component/auth/mail-verify/mail-verify.component';
import {StatusCode} from "./common/util/status-code";
import {CookieUtil} from "./common/util/cookie-util";
import {ResponseStatusCheckService} from "./common/util/response-status-check.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TableModule} from "primeng/table";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {CalendarModule} from "primeng/calendar";
import {LocaleCalendar} from "./common/util/locale-calendar";
import {ProgressBarModule} from "primeng/progressbar";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {InputTextareaModule} from "primeng/inputtextarea";
import {TooltipModule} from "primeng/tooltip";
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmationService} from "primeng/api";
import { UnknownErrorComponent } from './component/errors/unknown-error/unknown-error.component';
import { SystemErrorComponent } from './component/errors/system-error/system-error.component';
import { AutoInputComponent } from './component/setting/auto-input/auto-input.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    AttendanceVerifyComponent,
    AttendanceComponent,
    MailVerifyComponent,
    UnknownErrorComponent,
    SystemErrorComponent,
    AutoInputComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TableModule,
    ButtonModule,
    RippleModule,
    CalendarModule,
    ProgressBarModule,
    DialogModule,
    InputTextModule,
    InputTextareaModule,
    TooltipModule,
    ConfirmPopupModule
  ],
  providers: [
    CookieUtil,
    StatusCode,
    DatePipe,
    LocaleCalendar,
    ConfirmationService,
    ResponseStatusCheckService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
