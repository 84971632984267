import {Injectable} from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class DateUtil {

  /**
   * 获取时间
   * format: yyyyMM
   * @param date 日期
   */
  static getStrDate_yyyy_MM(date: Date): string {
    return date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, '0');
  }

  isBefore2(start: Date, end: Date): boolean {
    return moment(start).isBefore(end);
  }

  totalTime(onlineTime: any, offlineTime: any): string {

    const start = moment(onlineTime, 'HH:mm');
    const end = moment(offlineTime, 'HH:mm');

    start.seconds(0);
    end.seconds(0);

    start.milliseconds(0);
    end.milliseconds(0);

    const totalMinute = end.diff(start, 'minute', false);

    // hour
    const hourNumber = Math.floor(totalMinute / 60);
    // minute
    const minuteNumber = totalMinute % 60;

    return hourNumber.toString().padStart(2, '0') + ':' + minuteNumber.toString().padStart(2, '0');
  }

  dateToString_YYYY_MM_DD(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  dateToString_YYYY_MM(date: Date): string {
    return moment(date).format('YYYY-MM');
  }

  dateToString_YYYY_MM_KAN_JI(date: Date): string {
    return moment(date).format('YYYY年MM月');
  }
}
