<div class="pt-8 pb-10 pb-lg-34 pt-13 pt-lg-27">
  <div class="container">
    <div class="row justify-content-center mt-15">
      <div class="col-xxl-4">
        <div class="bg-white px-9 pt-9 pb-7 shadow-8 border-style">
          <div class="text-center pb-3">
            <img src="./assets/image/logo.svg" class="w-15" alt="logo">
          </div>
          <div class="text-center mb-10">
            <span class="font-size-5 font-en">Timestamp<span class="font-ch">&nbsp;AMS</span></span>
            <div class="font-size-2 font-en text-danger">{{errorMessage}}</div>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="loginSubmit(loginForm.value)" novalidate>
            <div class="form-group">
              <label class="font-size-3 text-black font-weight-600 mb-0">メールアドレス</label>
              <input type="email" formControlName="mail"
                     [ngClass]="{'ng-dirty ng-invalid': submitted && loginForm.controls.mail.errors}"
                     class="form-control input-height">
            </div>
            <div class="form-group">
              <label class="font-size-3 text-black font-weight-600 mb-0">パスワード</label>
              <input type="password" formControlName="password"
                     [ngClass]="{'ng-dirty ng-invalid': submitted && loginForm.controls.password.errors}"
                     class="form-control input-height">
            </div>

            <div class="form-group d-flex flex-wrap justify-content-between mb-0">
              <label for="terms-check" class="gr-check-input d-flex mb-5 mr-3">
                <input id="terms-check" class="d-none" type="checkbox" formControlName="remember">
                <span class="checkbox mr-5"></span>
                <span class="font-size-3 mb-0 line-height-reset mb-1 d-block">
                  30日間自動ログインを保持する(<span class="font-size-1 text-warning">デフォルトは一週間</span>)
                </span>
              </label>
            </div>

            <div class="mb-8">
              <button type="submit" class="btn btn-red-2 font-size-3 rounded-5 text-white w-100">
                <span *ngIf="!showSubmitLoader">ログイン</span>
                <i *ngIf="showSubmitLoader" class="fa fa-refresh fa-spin fa-2x fa-fw"></i>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
