import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {StatusCode} from "./status-code";
import {RoutePath} from "../route-path";

@Injectable({
  providedIn: 'root'
})

export class ResponseStatusCheckService {

  constructor(
    private router: Router,
    private statusCode: StatusCode
  ) {
  }

  // 过滤服务器返状态码
  statusCheck(response: any): any {

    // 用户未登录, 返回登录界面
    if (response?.statusCode === this.statusCode.NOT_LOGIN) {
      this.router.navigate([RoutePath.EMPTY]).then();
      return;
    }

    // システムエラー
    if(response?.statusCode === this.statusCode.SYSTEM_ERROR){
      this.router.navigate([RoutePath.SYSTEM_ERROR]).then();
      return;
    }

    // 未知异常
    if (response?.statusCode === this.statusCode.UNKNOWN_ERROR) {
      this.router.navigate([RoutePath.UNKNOWN_ERROR]).then();
      return;
    }

    // 返回给 component 进行处理
    return response;
  }
}
