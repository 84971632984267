import {HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {ApiPath} from './api-path';

export class BaseService extends ApiPath {

  constructor() {
    super();
    this.setAuthorization('c3RzZXJ2aWNlOmZZenJleWc1c2E0UQ==');
  }

  protected httpOptions: any = {
    // ヘッダ情報
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    withCredentials: true,
  };

  protected httpOptionsFileUpload: any = {
    // ヘッダ情報
    headers: new HttpHeaders({
      // 'Content-Type': 'application/json',
      // 1, 上传文件时这个不能要
      // 2, 使用FormData
      // 3, 后段不能使用 @RequestBody
      // 4, 如果file不是必须项的话, 用 undefined 判断, 如果不上传, 在FormData里不要添加
    }),
    withCredentials: true,
  };

  protected handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }

  public setAuthorization(token: string): void {
    if (!token) {
      return;
    }
    const bearerToken = `Bearer ${token}`;
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', bearerToken);
  }
}
