import { Component } from '@angular/core';
import {PrimeNGConfig} from "primeng/api";
import {LocaleCalendar} from "./common/util/locale-calendar";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'around-check-front';

  constructor(
    private primeNGConfig: PrimeNGConfig,
    private localeCalendar: LocaleCalendar,
  ) {
    // calender i18n
    this.primeNGConfig.setTranslation(this.localeCalendar.getLocaleCalendar());
  }

  ngOnInit(): void {
    this.primeNGConfig.ripple = true;
  }
}
