import {Component, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {AttendanceRecordService} from "../../service/attendance-record.service";
import {StatusCode} from "../../common/util/status-code";


@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {

  // ---------------------------------
  public isShowLoader = false;
  public showTopInitLoader = false;
  public showCenterInitLoader = false;
  public showBottomInitLoader = false;
  // ---------------------------------
  public name = '';
  public companyName = '';
  public customerName = '';
  // ---------------------------------
  // status: 1 出勤開始
  // status: 2 休憩開始
  // status: 3 休憩終了
  // status: 4 出勤終了
  // 以上状态全部从服务端获取,并设置
  public statusNum = 1;
  public statusHintNum = 1;
  public statusStr = '出勤開始';
  public nowStatusStr = '未出勤';

  public statusHintStrList: string[] = ['', '', '', ''];
  // ---------------------------------
  public nowDate: string | null = '';
  public nowTime = '';
  public dayOfWeek = '';

  // ---------------------------------
  constructor(
    private datePipe: DatePipe,
    private statusCode: StatusCode,
    private attendanceRecordService: AttendanceRecordService,
  ) {
    // 防止页面初始化时出现空白
    // 原因是当前component初始化开始后1秒 setInterval 才会执行
    this.nowDateTime();
    setInterval(() => {
      this.nowDateTime();
    }, 1000)
  }

  ngOnInit(): void {

    this.isShowLoader = true;
    this.showTopInitLoader = true;
    this.showCenterInitLoader = true;
    this.showBottomInitLoader = true;

    this.name = localStorage.getItem('name') as string;
    this.companyName = localStorage.getItem('companyName') as string;
    this.customerName = localStorage.getItem('customerName') as string;

    // init from server
    this.attendanceRecordService.attendanceRecordInit().subscribe(res => {
      if (res !== undefined) {
        // success
        if (res.statusCode === this.statusCode.SUCCESS) {
          this.statusNum = res?.result?.statusNum;
          this.statusHintNum = res?.result?.statusNum;
          this.statusStr = this.getStatusStrByStatusNum(res?.result?.statusNum);
          this.nowStatusStr = this.getNowStatusStrByStatusNum(res?.result?.statusNum);
          if (res?.result?.statusHintStrList !== undefined && res?.result?.statusHintStrList?.length >= 1) {
            this.statusHintStrList = res?.result?.statusHintStrList;
          }
          setTimeout(() => {
            this.isShowLoader = false;
            this.showTopInitLoader = false;
            this.showCenterInitLoader = false;
            this.showBottomInitLoader = false;
          }, 900);
        }
      }
    });
  }

  // 获取并设置当前日期・时间・曜日
  nowDateTime(): void {
    const now = new Date();
    // time
    this.nowTime = now.toLocaleTimeString();
    // date
    this.nowDate = this.datePipe.transform(now, 'yyyy年MM月dd日');
    // day of week
    this.dayOfWeek = this.transform(now);

    if (this.nowTime == '00:00:01') {
      // 过了晚上12点之后立马刷新页面
      // 防止出现一号出勤, 二号退勤的情况
      // FIXME 后端没有做相应的判断
      window.location.reload();
    }
  }

  // 获取曜日
  transform(date: Date): any {
    if (date !== undefined) {
      let weekArray = ['日曜日', '月曜日', '日曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
      let myDate = new Date(date);
      /** getDay() is Gets the day of the week, using local time. */
      return weekArray[myDate.getDay()];
    }
  }

  // 出勤开始或退勤
  postWorkStartOrEndTime(): void {
    this.isShowLoader = true;
    this.showCenterInitLoader = true;
    if (this.statusNum >= 5) {
      this.statusNum = 5;
      this.statusStr = this.getStatusStrByStatusNum(this.statusNum);
      this.nowStatusStr = this.getNowStatusStrByStatusNum(this.statusNum);
    } else {
      // -- 页面提示 --
      this.statusHintStrList[this.statusNum - 1] = this.getNowHoursMinutes(new Date());
      // request to server
      this.attendanceRecordService.attendanceRecordCreate().subscribe(res => {
        if (res !== undefined) {
          this.statusNum += 1;
          this.statusHintNum += 1;
          this.statusStr = this.getStatusStrByStatusNum(this.statusNum);
          this.nowStatusStr = this.getNowStatusStrByStatusNum(this.statusNum);
          setTimeout(() => {
            this.isShowLoader = false;
            this.showCenterInitLoader = false;
          }, 600)
        }
      });
    }
  }

  // attendance button content
  getStatusStrByStatusNum(num: number): string {
    switch (num) {
      case 1 :
        return '出勤開始';
      case 2 :
        return '休憩開始';
      case 3 :
        return '休憩終了';
      case 4 :
        return '出勤終了';
      default :
        return 'お疲れ様でした';
    }
  }

  // now status
  getNowStatusStrByStatusNum(num: number): string {
    switch (num) {
      case 1 :
        return '未出勤';
      case 2 :
        return '出勤済み';
      case 3 :
        return '休始済み';
      case 4 :
        return '休終済み';
      case 5 :
        return '退勤済み';
      default :
        return '';
    }
  }

  getNowHoursMinutes(nowDate: Date): string {
    const nowHours = nowDate.getHours().toString().padStart(2, '0');
    const nowMinutes = nowDate.getMinutes().toString().padStart(2, '0');
    return nowHours + ':' + nowMinutes;
  }
}
