import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LocaleCalendar {

  // p-calendar 国际化
  getLocaleCalendar(): any {
    return {
      dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
      monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
      monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
    };
  }
}
