import {Component, OnInit} from '@angular/core';
import {DatePipe} from "@angular/common";
import {StatusCode} from "../../common/util/status-code";
import {AttendanceRecordService} from "../../service/attendance-record.service";
import {AttendanceRecordListForm} from "../../form/attendance-record-list-form";
import {DateUtil} from "../../common/util/date-util";
import {QueryAttendanceRecordConditionForm} from "../../form/query-condition-form/query-attendance-record-condition-form";
import * as FileSaver from 'file-saver';
import autoTable from "jspdf-autotable";
import jsPDF from 'jspdf';
import {FontService} from "../../service/font.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ConfirmationService} from "primeng/api";
import {AroundFormControl} from "../../common/validate/around-form-control";

@Component({
  selector: 'app-project',
  templateUrl: './attendance-verify.component.html',
  styleUrls: ['./attendance-verify.component.css']
})
export class AttendanceVerifyComponent implements OnInit {

  // ------------------------
  public isClose = false;
  public isNotRecord = false;
  public isApproved = false;
  public customerName = '';
  public isRecordExists = false;
  public isShowCloseSubmitDialog = false;
  public closeSubmitForm!: FormGroup;
  // ------------------------
  public isCreate = false;
  public submitted = false;
  public detailDialogTitle = '';
  public attendanceForm!: FormGroup;
  public isShowDetailDialog = false;
  public isShowSubmitLoader = false;
  public formValidationErrorMessage = '';
  // ------------------------
  public cols!: any[];
  public exportColumns!: any[];
  public isShowLoader = false;
  // ------------------------
  public nowDate = new Date();
  public maxDate = new Date();
  public minDate = new Date('1948-09');
  public totalWorkTimeOfMonth = '';
  // ------------------------
  public name = '';
  public manageNo = '';
  // ------------------------
  public attendanceRecordList!: AttendanceRecordListForm[];
  public selectedAttendanceRecord!: AttendanceRecordListForm;
  public conditionForm = new QueryAttendanceRecordConditionForm();

  constructor(
    private dateUtil: DateUtil,
    private datePipe: DatePipe,
    private statusCode: StatusCode,
    private formBuilder: FormBuilder,
    private fontService: FontService,
    private attendanceRecordService: AttendanceRecordService,
  ) {
  }

  ngOnInit(): void {
    // 社員氏名
    this.name = localStorage.getItem('name') as string;
    // 社員番号
    this.manageNo = localStorage.getItem('manageNo') as string;

    // 初期
    this.getAttendanceRecord();

    this.cols = [
      {field: "dateAndWeek", header: "日付"},
      {field: "holiday", header: "国民祝日"},
      {field: "workStart", header: "出勤時間"},
      {field: "resStart", header: "休始時間"},
      {field: "resEnd", header: "休終時間"},
      {field: "workEnd", header: "退勤時間"},
      {field: "totalWorkTimeOfDay", header: "勤務時間"},
    ];

    this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));

    // 編集 の form init
    this.attendanceForm = this.formBuilder.group({
      uuid: new AroundFormControl(false, ''),
      date: new AroundFormControl(false, ''),
      workStart: new AroundFormControl(true, '', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      resStart: new AroundFormControl(true, '', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      resEnd: new AroundFormControl(true, '', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      workEnd: new AroundFormControl(true, '', Validators.pattern('\\d{1,2}:\\d{1,2}')),
      employeeRemarks: new AroundFormControl(true, '', Validators.maxLength(1000)),
    });
    // 締め申請のform init
    this.closeSubmitForm = this.formBuilder.group({
      year: new FormControl(''),
      month: new FormControl(''),
      remarks: new FormControl('ご確認をお願い致します。', [Validators.required, Validators.maxLength(1000)]),
    });
  }

  getAttendanceRecord(): void {
    this.isShowLoader = true;
    // 获取当月祝日
    const nowYearMonth = DateUtil.getStrDate_yyyy_MM(this.nowDate);
    this.attendanceRecordService.getJapanHoliday(nowYearMonth).subscribe(
      res => { // is success
        this.conditionForm.date = this.nowDate;
        this.conditionForm.holidayListForm = res;
        this.getAttendanceRecordByDate(this.conditionForm);
      },
      error => { // is error
        this.conditionForm.date = this.nowDate;
        this.getAttendanceRecordByDate(this.conditionForm);
        console.error('this 404 error is processed, will not affect the program in any way!');
      }
    );
    /**
     * 【注意】:
     *  这里获取祝日的API,在没有祝日的时候返回的是404
     *  所以需要对不管返回成功还是失败都要让程序继续走下去
     *  如果404的时候[holidayListForm]为Null,后端要做相应的判断
     *  TODO 应该定期的关注该祝日API返回值是否有修改, 比如目前2022-02-13今天为止在处理返回值上不是很友好(没有祝日直接返回404了)
     */
  }

  // 初期展示当前月份的勤怠記録
  // 获取当前月份的勤怠記録
  // 参数: 当前年和月
  getAttendanceRecordByDate(conditionForm: any): void {
    this.attendanceRecordService.getAttendanceRecordByDate(conditionForm).subscribe(data => {
      if (data !== undefined) {
        this.isClose = data?.result?.isClose;
        this.isApproved = data?.result?.isApproved;
        this.customerName = data?.result?.customerName;
        this.isNotRecord = data?.result?.isNotRecord;
        this.attendanceRecordList = data?.result?.data;
        this.totalWorkTimeOfMonth = data?.result?.totalWorkTimeOfMonth;
        this.isShowLoader = false;
        // 如果当月没有出勤时, PDFボタン禁止使用
        this.isRecordExists = data?.result?.isRecordExists;
      }
    });
  }

  // export excel
  exportExcel() {
    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.attendanceRecordList);
      const workbook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, {bookType: 'xlsx', type: 'array'});
      this.saveAsExcelFile(excelBuffer, 'products');
    });
  }

  exportPDF() {
    this.isShowLoader = true;
    setTimeout(() => {
      this.fontService.getFont().subscribe(data => {
        // create jspdf
        const doc = new jsPDF('p', 'pt', 'a4', false);
        doc.addFileToVFS('zenOldMincho-Regular.ttf', data);
        doc.addFont('zenOldMincho-Regular.ttf', 'mincho', 'normal');
        doc.setFont('mincho', 'normal');

        // now date to string yyyy年MM月
        let nowDate = this.dateUtil.dateToString_YYYY_MM_KAN_JI(this.nowDate);

        // set header title 1
        // doc.text(nowDate + '勤怠履歴', 215, 25);
        doc.setFontSize(18);
        const h1_text = nowDate + '勤怠履歴';
        const h1_fontSize = 18; // 文字大小
        const h1_pageWidth = doc.internal.pageSize.getWidth();
        const h1_textWidth = doc.getStringUnitWidth(h1_text) * h1_fontSize / doc.internal.scaleFactor;
        const h1_x = (h1_pageWidth - h1_textWidth) / 2; // 文本水平居中
        const h1_y = 40; // 文本在顶部展示，10 是顶部的边距
        doc.text(h1_text, h1_x, h1_y);

        // set header title 2
        doc.setFontSize(10);
        doc.setTextColor(128); // 设置为灰色，参数为灰度值（0-255）
        doc.text('社員氏名: ' + this.name, 40, 85);
        const hText = '稼働時間: ' + this.totalWorkTimeOfMonth;
        const hFontSize = 10; // 文字大小
        const hPageWidth = doc.internal.pageSize.getWidth();
        const hTextWidth = doc.getStringUnitWidth(hText) * hFontSize / doc.internal.scaleFactor;
        const hX = hPageWidth - hTextWidth - 40; // 40 是右侧的边距
        doc.text(hText, hX, 85);

        // set footer
        doc.setFontSize(8);
        const fText = 'Around Manage System (AMS) - www.around-manage.com';
        const fFontSize = 8; // 文字大小
        const fPageWidth = doc.internal.pageSize.getWidth();
        const fPageHeight = doc.internal.pageSize.getHeight();
        const fTextWidth = doc.getStringUnitWidth(fText) * fFontSize / doc.internal.scaleFactor;
        const fTextHeight = fFontSize;
        const x = (fPageWidth - fTextWidth) / 2; // 文本水平居中
        const y = fPageHeight - fTextHeight - 1; // 文本在底部展示，1 是底部的边距
        doc.text(fText, x, y);

        // set table body...
        autoTable(doc, {
          startY: 90, // 设置起始位置向下偏移
          styles: {font: 'mincho', fontStyle: 'normal'},
          body: this.attendanceRecordList as any,
          columns: this.exportColumns,
        });
        // set file name
        doc.save(nowDate + '勤怠履歴_' + this.name + '.pdf');

        this.isShowLoader = false;
      });
    }, 500);
  }

  // save excel file
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public attDialogClose(): void {
    // -------------- attendance form --------------
    this.attendanceForm.reset();
    this.isShowDetailDialog = false;
    this.formValidationErrorMessage = '';
    // ----------------- close form ----------------
    this.closeSubmitForm.reset();
    this.isShowCloseSubmitDialog = false;
    this.closeSubmitForm.controls.remarks.setValue('ご確認をお願い致します。');
  }

  // 显示详细Dialog
  public showAttendanceRecordDetailDialog(record: AttendanceRecordListForm): void {

    // 判断作成OR更新
    if (record.uuid !== undefined && record.uuid !== null && record.uuid !== '') {
      // 更新
      this.isCreate = false;
    } else {
      // 作成
      this.isCreate = true;
    }

    // 将现在的值设置到当前的Form中
    this.attendanceForm.controls.uuid.setValue(record?.uuid); // insert or update用
    this.attendanceForm.controls.date.setValue(record?.date); // insert or update用
    this.attendanceForm.controls.workStart.setValue(record?.workStart === '--' ? '' : record?.workStart);
    this.attendanceForm.controls.workEnd.setValue(record?.workEnd === '--' ? '' : record?.workEnd);
    this.attendanceForm.controls.resStart.setValue(record?.resStart === '--' ? '' : record?.resStart);
    this.attendanceForm.controls.resEnd.setValue(record?.resEnd === '--' ? '' : record?.resEnd);
    this.attendanceForm.controls.employeeRemarks.setValue(record?.employeeRemarks);

    // 查看用户本地是否存在【用户设定的自动输入内容】
    Object.keys(this.attendanceForm.controls).forEach((key, num) => {
      const control = this.attendanceForm.controls[key] as AroundFormControl;
      if (control.isCheckLocalExist && this.isCreate) {
        control.setValue(localStorage.getItem(key))
      }
    });

    this.detailDialogTitle = record?.dateAndWeek as string;
    this.isShowDetailDialog = true;
  }

  // attendance record submit
  public submitAttendanceRecordConfirm(form: any): void {

    this.submitted = true;
    if (this.attendanceForm.invalid) {
      return;
    }

    // 各种情况判断
    // 情况1、判断从服务端来的数据中有uuid存在、但没有(出勤,休始,休終,退勤,備考)时、这也是一个空表单、禁止提交给服务端
    // 情况2、判断从服务端来的数据中有uuid存在、同时也有(出勤,休始,休終,退勤,備考)时、这种情况允许提交空表单

    // 空表单: 没有(出勤,休始,休終,退勤,備考)
    let isEmptyForm = false;
    if (
      (form?.workStart !== null && form?.workStart !== '') || (form?.workEnd !== null && form?.workEnd !== '')
      || (form?.resStart !== null && form?.resStart !== '') || (form?.resEnd !== null && form?.resEnd !== '')
      || (form?.employeeRemarks !== null && form?.employeeRemarks !== '')
    ) {
      isEmptyForm = false;
    } else {
      isEmptyForm = true;
    }

    if (form?.uuid === null || form?.uuid === undefined || form?.uuid === '') {
      if (isEmptyForm) {
        // uuid 不存在, 并且还是空表单提交
        this.formValidationErrorMessage = '下記内容のいずれかを入力してください';
        return;
      }
    }

    this.isShowSubmitLoader = true;
    this.attendanceRecordService.attendanceRecordUpdate(form).subscribe(res => {
      if (res !== undefined) {
        // 提交了空表单
        if (res.statusCode === this.statusCode.EMPTY_FORM) {
          // uuid 不存在, 并且还是空表单提交
          this.formValidationErrorMessage = '下記内容のいずれかを入力してください';
        }

        // 时间的验证Error(例: 休憩開始時間 > 休憩終了時間,等,详细参照服务端)
        if (res.statusCode === this.statusCode.TIME_VALIDATE_FAILED) {
          // 时间验证未通过
          this.formValidationErrorMessage = '正しい例: [出勤时间] < [休憩開始時間] < [休憩終了時間] < [退勤時間]';
        }

        // OK
        if (res.statusCode === this.statusCode.SUCCESS) {
          this.attendanceForm.reset();
          this.isShowDetailDialog = false;
          // 刷新勤務表
          this.getAttendanceRecord();
        }

        this.isShowSubmitLoader = false;
      }
    });
  }

  // 締め申請
  public showCloseRequestSubmitDialog(): void {
    this.isShowCloseSubmitDialog = !this.isShowCloseSubmitDialog;
  }

  // 締め申請 submit
  public submitCloseRequestConfirm(form: any): void {
    this.submitted = true;
    if (this.closeSubmitForm.invalid) {
      return;
    }

    form.year = this.nowDate.getFullYear();
    form.month = this.nowDate.getMonth() + 1;

    // to server
    this.isShowSubmitLoader = true;
    this.attendanceRecordService.attendanceRecordCloseRequestSubmit(form).subscribe(res => {
      if (res != undefined) {
        this.isShowSubmitLoader = false;
        this.isShowCloseSubmitDialog = !this.isShowCloseSubmitDialog;
        // 重新加载
        this.getAttendanceRecord();
      }
    });
  }
}
