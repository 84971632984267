import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Constant} from "../common/util/constant";
import {RoutePath} from "../common/route-path";
import {CookieUtil} from "../common/util/cookie-util";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private cookieUtil: CookieUtil,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const uid = this.cookieUtil.getCookie(Constant.COOKIE.TIMESTAMP_ONE_TIME_TOKEN_);
    if (uid === null || uid === '') {
      this.router.navigate([RoutePath.EMPTY]).then();
      return false;
    }
    return true;
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }
}
