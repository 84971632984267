export class RoutePath {
  static EMPTY = '';
  static TIMESTAMP_AUTH_MAIL = 'timestamp/auth/mail';
  static TIMESTAMP_ATTENDANCE = 'timestamp/attendance';
  static TIMESTAMP_ATTENDANCE_VERIFY = 'timestamp/attendance/verify';
  static TIMESTAMP_SETTING_AUTO_INPUT = 'timestamp/setting/auto-input';

  static SYSTEM_ERROR = 'system-error';
  static UNKNOWN_ERROR = 'unknown-error';
}
